import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Upload } from 'tus-js-client';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Box,
    IconButton,
    Modal,
    Backdrop,
    Fade,
} from '@mui/material';
import { PlayArrow, CloudUpload, Delete } from '@mui/icons-material';

let socket = io('https://streamneo.com');

function VideoUpload() {
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [title, setTitle] = useState("");
    const [videos, setVideos] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");

    useEffect(() => {
        socket.on('upload-progress', (data) => {
            setProgress(data.progress);
        });

        fetchVideos();

        return () => {
            socket.off('upload-progress');
        };
    }, []);

    const fetchVideos = async () => {
        try {
            const response = await fetch('https://streamneo.com/user-videos', {
                headers: { 'Authorization': `${localStorage.getItem("token")}` }
            });
            if (response.ok) {
                const data = await response.json();
                setVideos(data.videos);
            } else {
                throw new Error('Failed to fetch videos');
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setProgress(0);
    };

    const uploadVideo = async () => {
        if (!file) {
            alert("Please select a file to upload.");
            return;
        }

        setUploading(true);
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        const response = await fetch("https://streamneo.com/server/least-active", requestOptions);
        const result = await response.json();
        socket.disconnect();
        socket = io(`http://${result.ipv4}`);
        socket.on('connect', () => {
            console.log('Connected to the dynamic server:', result.ipv4);
        });
        const upload = new Upload(file, {
            endpoint: `http://${result.ipv4}/files`,
            chunkSize: 5 * 1024 * 1024,
            retryDelays: [0, 1000, 3000, 5000],
            metadata: {
                filename: file.name,
                title,
                userId: localStorage.getItem("userId")
            },
            onError: (error) => {
                alert('Upload failed, please try again.');
                setUploading(false);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                const progress = (bytesUploaded / bytesTotal) * 100;
                setProgress(progress);
                socket.emit('upload-progress', { progress });
            },
            onSuccess: () => {
                alert('Upload completed!');
                setFile(null);
                setTitle("");
                setProgress(0);
                setUploading(false);
                fetchVideos();
            }
        });

        upload.start();
    };

    const handleOpen = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedVideoUrl("");
    };

    return (
        <Box display="flex" sx={{ fontFamily: 'Poppins, sans-serif', backgroundColor: '#f0f2f5' }}>
            {/* Left Side - Video Library */}
            <Box sx={{ width: '75%', paddingLeft: '30px', paddingTop: '20px', paddingRight: '30px', height: '85vh' }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: '600', color: '#1a202c', marginBottom: '20px', fontFamily: 'Poppins, sans-serif', }}>
                    Video Library
                </Typography>
                <Grid container spacing={3}>
                    {videos.map((video) => (
                        <Grid item xs={12} sm={4} key={video._id}>
                            <Card sx={{
                                borderRadius: '12px',
                                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
                                overflow: 'hidden',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.03)',
                                    boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.2)',
                                },
                                position: 'relative',
                                height: '200px'
                            }}>
                                <CardMedia
                                    component="img"
                                    image={`https://server.streamneo.com/${video.thumbnailPath.replace('/var/www/html/', '')}` || "default_thumbnail.jpg"}
                                    alt={video.title}
                                    sx={{
                                        borderRadius: '12px 12px 0 0',
                                        height: '200px',  // Adjust height for uniformity
                                        objectFit: 'cover',  // Ensures aspect ratio is maintained
                                        width: '100%',
                                    }}
                                />
                                <CardContent sx={{
                                    height: '200px',
                                    flexDirection: 'column',
                                    justifyContent: 'end',
                                    display: 'flex',
                                    position: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '0',
                                    right: '0',
                                    padding: '0px',
                                    margin: '0px',
                                }}>
                                    <Box sx={{
                                        position: 'absolute',
                                        bottom: '0',
                                        left: '0',
                                        right: '0',
                                    }}>
                                        <p
                                            variant="h6"
                                            style={{
                                                padding: '5px',
                                                margin: '0px',
                                                color: '#ffffff',
                                                fontWeight: '500',
                                                fontSize: '16px',
                                                fontFamily: 'Poppins',
                                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                                borderRadius: '8px',
                                                width: '100%',
                                                textAlign: 'left',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                height: '50px',
                                            }}>
                                            {video.title}
                                        </p>
                                    </Box>
                                    <Box sx={{
                                        alignSelf: 'center',
                                        display: 'flex',
                                        height: '100%',
                                        zIndex: 1,
                                    }}>
                                        <IconButton
                                            onClick={() => handleOpen(`https://server.streamneo.com/${video.uploadPath.replace('/var/www/html/', '')}`)}
                                            sx={{
                                                color: '#ffffff',
                                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                borderRadius: '50%',
                                                alignSelf: 'center',
                                                padding: '10px',
                                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
                                                transition: 'background-color 0.3s ease',
                                            }}
                                        >
                                            <PlayArrow sx={{ fontSize: '36px' }} />
                                        </IconButton>
                                    </Box>

                                    {/* Delete Button */}
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        zIndex: 1,
                                    }}>
                                        <IconButton sx={{ color: '#e53e3e', '&:hover': { backgroundColor: 'rgba(229, 62, 62, 0.1)' } }}>
                                            <Delete />
                                        </IconButton>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Right Side - Upload Section */}
            <Box sx={{ width: '25%', padding: '20px', backgroundColor: 'white' }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: '600', color: '#1a202c', marginBottom: '10px', fontFamily: 'Poppins, sans-serif', }}>
                    Upload Video
                </Typography>

                <TextField
                    type="file"
                    onChange={handleFileChange}
                    fullWidth
                    variant="outlined"
                    sx={{
                        "& fieldset": { borderRadius: '10px' },
                        mb: 2,
                        margin: '0px',
                        padding: '0px',
                        fontFamily: 'Poppins, sans-serif',
                    }}
                />
                {file && <Typography variant="body2" color="textSecondary">Selected file: {file.name}</Typography>}

                <TextField
                    label="Enter Video Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{
                        "& fieldset": { borderRadius: '10px' },
                        mb: 2,
                        fontFamily: 'Poppins, sans-serif',
                    }}
                />

                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={uploadVideo}
                    disabled={uploading}
                    startIcon={<CloudUpload />}
                    sx={{
                        backgroundColor: '#6a00ff',
                        color: 'white',
                        borderRadius: '10px',
                        mt: 2,
                        margin: '0px',
                        fontFamily: 'Poppins, sans-serif',
                        "&:hover": { backgroundColor: '#5a00e6' },
                    }}
                >
                    {uploading ? 'Uploading...' : 'Upload Video'}
                </Button>

                {uploading && (
                    <Box sx={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
                        <Typography variant="body2">Progress: {progress.toFixed(2)}%</Typography>
                        <CircularProgress variant="determinate" value={progress} sx={{ marginTop: '10px' }} />
                    </Box>
                )}
            </Box>

            {/* Modal for video playback */}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        outline: 0
                    }}>
                        <Typography variant="h6" component="h2" gutterBottom>
                            Video Playback
                        </Typography>
                        <video autoPlay src={selectedVideoUrl} controls style={{ width: '100%' }} />
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
}

export default VideoUpload;