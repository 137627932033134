
import React from 'react';
import '../App.css';

function LandingPage() {
    return (
        <div className="container">
            {/* Header */}
            <header className="header">
                <div className="logo">projectX</div>
                {/* <nav className="menu">
                    <a href="#">Features</a>
                    <a href="#">Solutions</a>
                    <a href="#">Pricing</a>
                    <a href="#">Resources</a>
                </nav> */}
                <div className="header-buttons">
                    <button className="btn login">Log In</button>
                    <button className="btn request-demo">Get Started</button>
                </div>
            </header>

            {/* Main Content */}
            <main className="content">
                <section className="text-section">
                    <h1 className="main-title">All-in-one live <i>streaming</i> platform for you</h1>
                    <p className="subtext">
                        Take your YouTube channel to the next level with projectX.
                        The easiest way to build & maintain a 24 hour live stream using pre-recorded videos.
                    </p>
                    <div className="header-buttons">
                        <button className="btn login">Get Started</button>
                        <button className="btn request-demo">Contact Us</button>
                    </div>
                </section>

                {/* Overlay Images */}
                <div className="image-overlay">
                    <img src={require('../images/home.jpg')} alt="Background" />
                    <div className="overlay-card">
                        <p>Get on top of the search since live videos have priority over regular ones, making it easier for you to get more views, followers & profit.</p>
                    </div>
                    <div className="profile-card">
                        {/* <img src={require('./images/profile.jpg')} alt="Profile" /> */}
                        <p>The projectX is amazing, and the live stream is a really great tool! I can rest assured that the live stream is performing perfectly 24 hours a day, 7 days a week. We are seriously considering scaling up streams across all our channels.</p>
                        <button className="rating-btn">- Prasun Ranjan</button>
                    </div>
                </div>
            </main>

            {/* Trusted Logos */}
            <footer className="trusted-logos">
                <p>Trusted by partners around the globe</p>
                <div className="logos">
                    {/* Insert logos here */}
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;