import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Checkbox, FormControlLabel } from '@mui/material';
import Google from '@mui/icons-material/Google';
import { register } from '../api';

function Register() {
    const [form, setForm] = useState({ username: '', email: '', password: '', confirmPassword: '', agreement: false });
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!form.agreement) {
            alert("You must agree to the terms.");
            return;
        }
        setIsLoading(true);
        try {
            await register(form);
            alert("Registration successful!");
            window.location.href = "/login"; // Redirect to login page after successful registration
        } catch (error) {
            console.error("Error registering user:", error);
            alert("Registration failed.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container
            maxWidth="lg"
            disableGutters
            sx={{
                display: 'flex',
                height: '100vh',
                width: '100vw',
                fontFamily: 'Poppins, sans-serif',
                overflow: 'hidden',
            }}
        >
            {/* Left Section */}
            <Box
                sx={{
                    width: { xs: '100%', md: '50%' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: 4,
                    margin: '20px',
                    borderRadius: '20px',
                    backgroundColor: '#f8fafc',
                    boxShadow: { md: '5px 0 5px rgba(0, 0, 0, 0.1)' },
                }}
            >
                <p
                    style={{
                        fontWeight: 700,
                        fontSize: '36px',
                        color: '#333',
                        fontFamily: 'Poppins, sans-serif',
                        margin: '10px',
                        padding: '0px'
                    }}
                >
                    24/7 Youtube Live Stream
                    Pre-Recorded Videos
                </p>
                <p
                    style={{
                        fontSize: '18px',
                        color: '#333',
                        fontFamily: 'Poppins, sans-serif',
                        margin: '10px',
                        padding: '0px'
                    }}>Take your YouTube channel to the next level with Upstream.
                    The easiest way to build & maintain a 24 hour live stream using pre-recorded videos.
                </p>
                <Box
                    sx={{
                        mt: 4,
                        p: 3,
                        backgroundColor: '#2d2d2f',
                        color: 'white',
                        borderRadius: '12px',
                        maxWidth: '75%',
                        borderRadius: '20px',
                        fontFamily: 'Poppins, sans-serif',
                    }}
                >
                    <Typography variant="body2" style={{
                        fontSize: '18px',
                        fontFamily: 'Poppins, sans-serif',
                        margin: '10px',
                        padding: '0px'
                    }} fontStyle="italic">
                        "I'm impressed with the results I've seen since starting to use this product; I began receiving clients and projects in the first week."
                    </Typography>
                    <Typography variant="body2" mt={1} style={{
                        fontSize: '14px',
                        fontFamily: 'Poppins, sans-serif',
                        margin: '10px',
                        padding: '0px'
                    }} >
                        - Jonas Kim, Product Designer
                    </Typography>
                </Box>
            </Box>

            {/* Right Section */}
            <Box
                sx={{
                    width: { xs: '100%', md: '50%' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '40px',
                    backgroundColor: '#ffffff',
                    fontFamily: 'Poppins, sans-serif',
                }}
            >
                <div style={{
                    fontWeight: '700',
                    fontSize: '24px',
                    margin: '10px'
                }}>projectX</div>
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                        fontWeight: 600,
                        color: '#333',
                        mb: 1,
                        fontFamily: 'Poppins, sans-serif',
                    }}
                >
                    Get Started
                </Typography>
                <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                        color: '#666',
                        mb: 3,
                        fontSize: '14px',
                        fontFamily: 'Poppins, sans-serif',
                    }}
                >
                    Create your account now
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '360px' }}>
                    <TextField
                        label="Full name"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setForm({ ...form, username: e.target.value })}
                        sx={{
                            "& fieldset": { borderRadius: '10px' },
                            mb: 2,
                            fontFamily: 'Poppins, sans-serif',
                        }}
                    />
                    <TextField
                        label="Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setForm({ ...form, email: e.target.value })}
                        sx={{
                            "& fieldset": { borderRadius: '10px' },
                            mb: 2,
                            fontFamily: 'Poppins, sans-serif',
                        }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setForm({ ...form, password: e.target.value })}
                        sx={{
                            "& fieldset": { borderRadius: '10px' },
                            mb: 2,
                            fontFamily: 'Poppins, sans-serif',
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={form.agreement}
                                onChange={(e) => setForm({ ...form, agreement: e.target.checked })}
                                sx={{
                                    color: '#6a00ff',
                                    '&.Mui-checked': { color: '#6a00ff' },
                                }}
                            />
                        }
                        label={
                            <Typography variant="body2" sx={{ color: '#666', fontFamily: 'Poppins, sans-serif' }}>
                                I agree to the <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms">Terms of Service</a>
                            </Typography>
                        }
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        size="large"
                        disabled={isLoading || !form.agreement || !form.email || !form.password || !form.username}
                        sx={{
                            backgroundColor: '#6a00ff',
                            color: 'white',
                            borderRadius: '10px',
                            mt: 2,
                            fontFamily: 'Poppins, sans-serif',
                            "&:hover": { backgroundColor: '#5a00e6' },
                        }}
                    >
                        {isLoading ? "Registering..." : "Sign Up"}
                    </Button>
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{
                            mt: 3,
                            color: '#666',
                            fontFamily: 'Poppins, sans-serif',
                        }}
                    >
                        Have an account? <a href="/login" style={{ color: '#6a00ff' }}>Login</a>
                    </Typography>
                </form>
            </Box>
        </Container >
    );
}

export default Register;