import React, { useState } from 'react';
import {
    AppBar, Toolbar, IconButton, Typography, Button, Container, Box, Menu, MenuItem, Avatar, Card, CardContent, Grid
} from '@mui/material';
import { VideoCall, CloudUpload, VpnKey } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AccountCircle } from '@mui/icons-material';
import SlotsScreen from './SlotsScreen';
import VideoUpload from './VideoUpload';

// Create a custom theme with Poppins as the default font
const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
    },
});

const Header = ({ onClick }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static" color="transparent" elevation={0} sx={{ borderBottom: '1px solid #e0e0e0' }}>
            <Toolbar>
                <div style={{
                    fontWeight: '700',
                    fontSize: '24px',
                    margin: '10px',
                    flexGrow: 1,
                }}>projectX</div>

                {/* Navigation Links */}
                {['Dashboard', 'Streams', 'Videos', 'Billing',].map((item, index) => (
                    <Button onClick={() => {
                        onClick(index);
                    }} key={item} color="inherit" sx={{ mx: 1 }}>
                        {item}
                    </Button>
                ))}

                {/* Profile Avatar */}
                <IconButton onClick={handleMenuOpen} color="inherit">
                    <Avatar alt="User Avatar" />
                </IconButton>

                {/* Profile Dropdown Menu */}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
                    <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

const Home = ({ userName = "Prasun" }) => {

    const [screen, setScreen] = useState(1);

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Header onClick={(index) => {
                    setScreen(index);
                }} />
                {screen === 1 && <SlotsScreen />}
                {screen === 2 && <VideoUpload />}
            </Box>
        </ThemeProvider>
    );
};

export default Home;