const BASE_URL = "https://streamneo.com";

// Utility function to set up headers with authorization if a token exists
const getHeaders = () => {
    const headers = { "Content-Type": "application/json" };
    const token = localStorage.getItem("token");
    if (token) {
        headers["Authorization"] = `${token}`;
    }
    return headers;
};

// Register
export const register = async (data) => {
    const response = await fetch(`${BASE_URL}/register`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error("Error registering user");
    return await response.json();
};

// Login
export const login = async (data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify(data);
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };
    const response = await fetch(`${BASE_URL}/login`, requestOptions);
    if (!response.ok) throw new Error("Error logging in");
    return await response.json();
};

// Fetch user videos
export const fetchVideos = async () => {
    const response = await fetch(`${BASE_URL}/videos`, {
        method: "GET",
        headers: getHeaders(),
    });
    if (!response.ok) throw new Error("Error fetching videos");
    return await response.json();
};

// Start stream for a selected video
export const startStream = async (videoId) => {
    const response = await fetch(`${BASE_URL}/stream/${videoId}`, {
        method: "POST",
        headers: getHeaders(),
    });
    if (!response.ok) throw new Error("Error starting stream");
    return await response.json();
};