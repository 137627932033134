import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Upload } from 'tus-js-client';
import {
    CircularProgress,

    IconButton,
    Modal,
    Backdrop,
    Fade,
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Typography, FormControl, InputLabel, Select, MenuItem, TextField,
    Grid, Card, CardMedia, CardContent, Box
} from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import { PlayArrow, CloudUpload, Delete } from '@mui/icons-material';

let socket = io('https://streamneo.com');

function SlotsScreen() {
    const [newSlot, setNewSlot] = useState({
        title: "",
        quality: "720p",
        duration: "1_day"
    });
    const [slots, setSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [title, setTitle] = useState("");
    const [videos, setVideos] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const [streamKey, setStreamKey] = useState("");

    useEffect(() => {
        fetchSlots();
        fetchVideos();
    }, []);

    const fetchSlots = async () => {
        try {
            const response = await fetch("https://streamneo.com/user/slots", {
                headers: { Authorization: localStorage.getItem("token") }
            });
            const data = await response.json();
            setSlots(data);
        } catch (error) {
            console.error("Error fetching slots:", error);
        }
    };

    const fetchVideos = async () => {
        try {
            const response = await fetch('https://streamneo.com/user-videos', {
                headers: { 'Authorization': `${localStorage.getItem("token")}` }
            });
            if (response.ok) {
                const data = await response.json();
                setVideos(data.videos);
            } else {
                throw new Error('Failed to fetch videos');
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
    };

    const handleAddSlot = async () => {
        try {
            await fetch("https://streamneo.com/slots", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token")
                },
                body: JSON.stringify(newSlot)
            });
            setOpen(false);
            fetchSlots();
        } catch (error) {
            console.error("Error creating slot:", error);
        }
    };

    const handleOpen = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedVideoUrl("");
    };

    const handleCloseStreamDialog = () => {
        setDialogOpen(false);
        setSelectedVideo("");
        setStreamKey("");
    };

    const handleStartStream = async () => {
        try {
            const response = await fetch(`https://streamneo.com/server/start-stream/${selectedSlot}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token")
                },
                body: JSON.stringify({ streamKey, videoId: selectedVideo })
            });
            const result = await response.text();
            fetchSlots();
            fetchVideos();
            handleCloseStreamDialog();
        } catch (error) {
            console.error("Error starting stream:", error);
        }
    };

    return (
        <Box display="flex" sx={{ fontFamily: 'Poppins, sans-serif', backgroundColor: '#f0f2f5' }}>
            {/* Left Side - Video Library */}
            <Box sx={{ width: '75%', paddingLeft: '30px', paddingTop: '20px', paddingRight: '30px', height: '85vh' }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: '600', color: '#1a202c', marginBottom: '20px', fontFamily: 'Poppins, sans-serif', }}>
                    Streams
                </Typography>
                <Grid container spacing={3}>
                    {slots.map((video) => (
                        <Grid item xs={12} sm={4} key={video._id}>
                            <Card sx={{
                                borderRadius: '12px',
                                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
                                overflow: 'hidden',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.03)',
                                    boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.2)',
                                },
                                position: 'relative',
                                height: '200px'
                            }}>
                                {video.isActive ? <CardMedia
                                    component="img"
                                    image={`https://server.streamneo.com/${video.video.thumbnailPath.replace('/var/www/html/', '')}` || "default_thumbnail.jpg"}
                                    alt={video.title}
                                    sx={{
                                        borderRadius: '12px 12px 0 0',
                                        height: '200px',  // Adjust height for uniformity
                                        objectFit: 'cover',  // Ensures aspect ratio is maintained
                                        width: '100%',
                                    }}
                                /> : null}
                                <CardContent sx={{
                                    height: '200px',
                                    flexDirection: 'column',
                                    justifyContent: 'end',
                                    display: 'flex',
                                    position: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '0',
                                    right: '0',
                                    padding: '0px',
                                    margin: '0px',
                                }}>
                                    <Box sx={{
                                        position: 'absolute',
                                        bottom: '0',
                                        left: '0',
                                        right: '0',
                                    }}>
                                        <p
                                            variant="h6"
                                            style={{
                                                padding: '5px',
                                                margin: '0px',
                                                color: '#ffffff',
                                                fontWeight: '500',
                                                fontSize: '16px',
                                                fontFamily: 'Poppins',
                                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                                borderRadius: '8px',
                                                width: '100%',
                                                textAlign: 'left',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                height: '50px',
                                            }}>
                                            {video.title}
                                        </p>
                                    </Box>
                                    <Box sx={{
                                        alignSelf: 'center',
                                        display: 'flex',
                                        height: '100%',
                                        zIndex: 1,
                                    }}>
                                        {video.isActive ? <IconButton
                                            onClick={() => {
                                                const myHeaders = new Headers();
                                                myHeaders.append("Content-Type", "application/json");

                                                const requestOptions = {
                                                    method: "POST",
                                                    headers: myHeaders,
                                                    redirect: "follow"
                                                };

                                                fetch(`https://server.streamneo.com/end-stream/${video._id}`, requestOptions)
                                                    .then((response) => response.text())
                                                    .then((result) => {
                                                        fetchSlots();
                                                        fetchVideos();
                                                    })
                                                    .catch((error) => console.error(error));
                                            }}
                                            sx={{
                                                color: '#ffffff',
                                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                borderRadius: '50%',
                                                alignSelf: 'center',
                                                padding: '10px',
                                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
                                                transition: 'background-color 0.3s ease',
                                            }}
                                        >
                                            <StopIcon sx={{ fontSize: '36px' }} />
                                        </IconButton> : <IconButton
                                            onClick={() => {
                                                setDialogOpen(true);
                                                setSelectedSlot(video._id);
                                            }}
                                            sx={{
                                                color: '#ffffff',
                                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                borderRadius: '50%',
                                                alignSelf: 'center',
                                                padding: '10px',
                                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.9)' },
                                                transition: 'background-color 0.3s ease',
                                            }}
                                        >
                                            <PlayArrow sx={{ fontSize: '36px' }} />
                                        </IconButton>}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Right Side - Upload Section */}
            <Box sx={{ width: '25%', padding: '20px', backgroundColor: 'white' }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: '600', color: '#1a202c', marginBottom: '10px', fontFamily: 'Poppins, sans-serif', }}>
                    Purchase Streams
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'left' }}>
                    Every stream is a server instance that you can manage from this dashboard.
                </Typography>
                <TextField
                    label="Title"
                    value={newSlot.title}
                    onChange={(e) => setNewSlot({ ...newSlot, title: e.target.value })}
                    fullWidth
                    variant="outlined"
                    sx={{
                        "& fieldset": { borderRadius: '10px' },
                        mb: 2,
                        margin: '0px',

                        marginTop: '10px',
                        padding: '0px',
                        fontFamily: 'Poppins, sans-serif',
                    }}
                />
                <FormControl fullWidth
                    variant="outlined"
                    sx={{
                        "& fieldset": { borderRadius: '10px' },
                        mb: 2,
                        margin: '0px',

                        marginTop: '10px',
                        padding: '0px',
                        fontFamily: 'Poppins, sans-serif',
                    }}>
                    <InputLabel>Quality</InputLabel>
                    <Select
                        value={newSlot.quality}
                        onChange={(e) => setNewSlot({ ...newSlot, quality: e.target.value })}
                    >
                        <MenuItem value="720p">720p</MenuItem>
                        <MenuItem value="1080p">1080p</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth
                    variant="outlined"
                    sx={{
                        "& fieldset": { borderRadius: '10px' },
                        mb: 2,
                        margin: '0px',

                        marginTop: '10px',
                        padding: '0px',
                        fontFamily: 'Poppins, sans-serif',
                    }}>
                    <InputLabel>Duration</InputLabel>
                    <Select
                        value={newSlot.duration}
                        onChange={(e) => setNewSlot({ ...newSlot, duration: e.target.value })}
                    >
                        <MenuItem value="1_day">1 Day</MenuItem>
                        <MenuItem value="1_month">1 Month</MenuItem>
                    </Select>
                </FormControl>
                <div style={{ height: '10px', width: '20px' }} />
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={handleAddSlot}
                    startIcon={<CloudUpload />}
                    sx={{
                        backgroundColor: '#6a00ff',
                        color: 'white',
                        borderRadius: '10px',
                        mt: 2,
                        margin: '0px',
                        marginTop: '10px',
                        fontFamily: 'Poppins, sans-serif',
                        "&:hover": { backgroundColor: '#5a00e6' },
                    }}
                >
                    {uploading ? 'Uploading...' : 'Purchase Stream'}
                </Button>

                {uploading && (
                    <Box sx={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
                        <Typography variant="body2">Progress: {progress.toFixed(2)}%</Typography>
                        <CircularProgress variant="determinate" value={progress} sx={{ marginTop: '10px' }} />
                    </Box>
                )}
            </Box>

            {/* Modal for video playback */}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        outline: 0
                    }}>
                        <Typography variant="h6" component="h2" gutterBottom>
                            Video Playback
                        </Typography>
                        <video autoPlay src={selectedVideoUrl} controls style={{ width: '100%' }} />
                    </Box>
                </Fade>
            </Modal>
            <Dialog
                open={dialogOpen}
                onClose={handleCloseStreamDialog}
                maxWidth="md" // Increase the dialog width
                fullWidth
            >
                <DialogTitle>Select Video and Stream Key</DialogTitle>
                <DialogContent>
                    <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <Grid container spacing={2}>
                            {videos.map((video) => (
                                <Grid item xs={4} key={video._id}>
                                    <Card
                                        onClick={() => setSelectedVideo(video._id)}
                                        sx={{
                                            cursor: 'pointer',
                                            border: selectedVideo === video._id ? '2px solid #6a00ff' : '1px solid #ddd',
                                            padding: 1,
                                            borderRadius: '8px'
                                        }}>
                                        <CardMedia
                                            component="img"
                                            image={`https://server.streamneo.com/${video.thumbnailPath.replace('/var/www/html/', '')}`}
                                            alt={video.title}
                                            sx={{ height: '120px', objectFit: 'cover', borderRadius: '8px' }}
                                        />
                                        <CardContent>
                                            <Typography variant="subtitle1" sx={{ fontWeight: '600', fontSize: '14px' }}>
                                                {video.title}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                File Size: {video.videoSize}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <TextField
                        label="Stream Key"
                        value={streamKey}
                        onChange={(e) => setStreamKey(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseStreamDialog} color="secondary">Cancel</Button>
                    <Button
                        onClick={handleStartStream}
                        color="primary"
                        disabled={!selectedVideo || !streamKey}
                    >
                        Start Stream
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default SlotsScreen;