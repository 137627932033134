import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { login } from '../api';

function Login() {
    const [form, setForm] = useState({ email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await login(form);
            localStorage.setItem("token", response.token); // Store JWT token for auth
            localStorage.setItem("userId", response.user._id); // Store user ID for auth
            window.location.href = "/home"; // Redirect to video library
        } catch (error) {
            console.error("Login error:", error);
            alert("Login failed. Check your credentials.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRegisterRedirect = () => {
        window.location.href = "/register"; // Redirect to register page
    };

    return (
        <Container maxWidth="lg" disableGutters sx={{ display: 'flex', height: '100vh', fontFamily: 'Poppins, sans-serif', overflow: 'hidden' }}>
            {/* Left Section */}
            <Box
                sx={{
                    width: { xs: '100%', md: '50%' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: 4,
                    margin: '20px',
                    borderRadius: '20px',
                    backgroundColor: '#f8fafc',
                    boxShadow: { md: '5px 0 5px rgba(0, 0, 0, 0.1)' },
                }}
            >
                <p
                    style={{
                        fontWeight: 700,
                        fontSize: '36px',
                        color: '#333',
                        fontFamily: 'Poppins, sans-serif',
                        margin: '10px',
                        padding: '0px'
                    }}
                >
                    24/7 Youtube Live Stream
                    Pre-Recorded Videos
                </p>
                <p
                    style={{
                        fontSize: '18px',
                        color: '#333',
                        fontFamily: 'Poppins, sans-serif',
                        margin: '10px',
                        padding: '0px'
                    }}>Take your YouTube channel to the next level with Upstream.
                    The easiest way to build & maintain a 24 hour live stream using pre-recorded videos.
                </p>
                <Box
                    sx={{
                        mt: 4,
                        p: 3,
                        backgroundColor: '#2d2d2f',
                        color: 'white',
                        borderRadius: '12px',
                        maxWidth: '75%',
                        borderRadius: '20px',
                        fontFamily: 'Poppins, sans-serif',
                    }}
                >
                    <Typography variant="body2" style={{
                        fontSize: '18px',
                        fontFamily: 'Poppins, sans-serif',
                        margin: '10px',
                        padding: '0px'
                    }} fontStyle="italic">
                        "I'm impressed with the results I've seen since starting to use this product; I began receiving clients and projects in the first week."
                    </Typography>
                    <Typography variant="body2" mt={1} style={{
                        fontSize: '14px',
                        fontFamily: 'Poppins, sans-serif',
                        margin: '10px',
                        padding: '0px'
                    }} >
                        - Prasun Ranjan, Product Designer
                    </Typography>
                </Box>
            </Box>

            {/* Right Section */}
            <Box
                sx={{
                    width: { xs: '100%', md: '50%' },
                    display: 'flex',
                    padding: '40px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    fontFamily: 'Poppins, sans-serif',
                }}
            >
                <Box sx={{ width: '80%', maxWidth: '400px', textAlign: 'center' }}>
                    <div style={{
                        fontWeight: '700',
                        fontSize: '24px',
                        margin: '10px'
                    }}>projectX</div>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontWeight: 600,
                            color: '#333',
                            mb: 1,
                            fontFamily: 'Poppins, sans-serif',
                        }}
                    >
                        Welcome Back
                    </Typography>
                    <Typography
                        variant="body2"
                        gutterBottom
                        sx={{
                            color: '#666',
                            mb: 3,
                            fontSize: '14px',
                            fontFamily: 'Poppins, sans-serif',
                        }}
                    >
                        Login to your account
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Email"
                            type="email"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => setForm({ ...form, email: e.target.value })}
                            sx={{
                                "& fieldset": { borderRadius: '10px' },
                                mb: 2,
                                fontFamily: 'Poppins, sans-serif',
                            }}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => setForm({ ...form, password: e.target.value })}
                            sx={{
                                "& fieldset": { borderRadius: '10px' },
                                mb: 2,
                                fontFamily: 'Poppins, sans-serif',
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            size="large"
                            disabled={isLoading || !form.email || !form.password}
                            sx={{
                                backgroundColor: '#6a00ff',
                                color: 'white',
                                borderRadius: '10px',
                                mt: 2,
                                fontFamily: 'Poppins, sans-serif',
                                "&:hover": { backgroundColor: '#5a00e6' },
                            }}
                        >
                            {isLoading ? "Logging in..." : "Login"}
                        </Button>
                        <Typography
                            variant="body2"
                            align="center"
                            sx={{
                                mt: 3,
                                color: '#666',
                                fontFamily: 'Poppins, sans-serif',
                            }}
                        >
                            Don’t have an account? <a href="/register" style={{ color: '#6a00ff' }}>Register</a>
                        </Typography>
                    </form>
                </Box>
            </Box>
        </Container>
    );
}

export default Login;